import {
  Box,
  Input,
  Flex,
  Text,
  IconButton,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import UsuarioAvatar from './UsuarioAvatar';
import CalaveraNoChilla from './CalaveraNoChilla';
import axios from 'axios';

const UsuarioCard = ({ onDelete, ids }) => {
  const [usuarios, setUsuarios] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [usuariosVisibles, setUsuariosVisibles] = useState(3); // Cambia esto para mostrar más/menos usuarios inicialmente
  const observer = useRef(null);
  const toast = useToast();

  // Función de intersección para cargar más usuarios
  const lastUsuarioElementRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && usuariosVisibles <= usuarios.length) {
          setUsuariosVisibles(prevUsuariosVisibles => prevUsuariosVisibles + 3); // Aquí decides cuántos usuarios más cargar
        }
      });
      if (node) observer.current.observe(node);
    },
    [usuariosVisibles, usuarios.length]
  );

  function getUsuarios() {
    const url = `https://putipatrulla-dev-dcrj.2.us-1.fl0.io/usuarios/${ids.join(
      ','
    )}`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const usuariosConImagen = data.map(usuario => {
          console.log(usuario);
          usuario.urlImagen =
            'https://putipatrulla.com/' + usuario.id_telegram + '.jpg';
          return usuario;
        });
        setUsuarios(usuariosConImagen);
      })
      .catch(error => console.error('Error al obtener los usuarios:', error));
  }

  useEffect(() => {
    console.log(ids);
    getUsuarios();
  }, [ids]);

  const handleDelete = usuario => {
    const confirmar = window.confirm(
      `¿Estás seguro de que quieres eliminar a ${usuario.primer_nombre} ${usuario.apellido}?`
    );
    if (!confirmar) return;
    const url = 'https://putipatrulla-dev-dcrj.2.us-1.fl0.io/eliminar-usuario';
    const userId = usuario.id;

    axios
      .post(url, { userId })
      .then(response => {
        // Aquí puedes realizar cualquier acción adicional después de eliminar el usuario
        onDelete(usuario.id);
        getUsuarios();
        toast({
          title: 'Usuario eliminado.',
          description: `Has eliminado a ${usuario.primer_nombre} ${usuario.apellido}. `,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        console.error('Error al eliminar el usuario:', error);
        // Aquí puedes manejar el error de acuerdo a tus necesidades
      });
  };

  // Filtrar los usuarios basado en el texto de búsqueda
  const usuariosFiltrados = usuarios
    .slice(0, usuariosVisibles)
    .filter(usuario => {
      const primerNombre = usuario.primer_nombre || '';
      const apellido = usuario.apellido || '';
      const nombreUsuario = usuario.nombre_usuario || '';
      const telegramId = usuario.id_telegram.toString(); // Convertir a cadena

      return (
        primerNombre.toLowerCase().includes(filtro.toLowerCase()) ||
        apellido.toLowerCase().includes(filtro.toLowerCase()) ||
        nombreUsuario.toLowerCase().includes(filtro.toLowerCase()) ||
        telegramId.toLowerCase().includes(filtro.toLowerCase()) // Filtrar por ID de Telegram
      );
    });

  return (
    <Box
      height={'80vh'}
      style={{
        overflowY: 'scroll',
        margin: '3px 15px',
        scrollbarWidth: 'thin',
        scrollbarColor: '#fad4eb',
      }}
    >
      <Box mb={4}>
        <Input
          placeholder="Buscar por nombre apellido o id de telegram"
          onChange={e => setFiltro(e.target.value)}
        />
      </Box>
      {usuariosFiltrados.map((usuario, i) => (
        <Flex
          direction="row"
          align="stretch"
          p={4}
          boxShadow="md"
          borderRadius="lg"
          mb={2}
          bg="#fad4eb"
          key={usuario.id_telegram} // Mejor usar un identificador único como key
          ref={
            usuariosFiltrados.length === i + 1 ? lastUsuarioElementRef : null
          } // Referencia al último elemento si es el último usuario visible
        >
          <Flex align="center" flex="1">
            <UsuarioAvatar usuario={usuario} />
            <Box ml={4}>
              <Text
                fontWeight="bold"
                color="black"
                fontSize={{ base: '14px', md: '16px', lg: '18px' }}
              >
                {usuario.primer_nombre} {usuario.apellido}
              </Text>
              <Text
                color="black"
                fontSize={{ base: '14px', md: '16px', lg: '18px' }}
              >
                {usuario.nombre_usuario !== null
                  ? '@' + usuario.nombre_usuario
                  : ''}
              </Text>
            </Box>
          </Flex>
          {/* Aquí irían los iconos de los grupos/canales */}
          <Flex align="center" flex="1">
            <Text
              fontSize={{ base: '14px', md: '16px', lg: '18px' }}
              mr={4}
              color="black"
              display={{ base: 'none', md: 'block' }}
            >
              {new Date(usuario.fecha_ingreso).toLocaleDateString()}
            </Text>
          </Flex>
          <HStack>
            <CalaveraNoChilla id={usuario.id} />
            <IconButton
              icon={<DeleteIcon />}
              color="black"
              variant="outline"
              onClick={() => handleDelete(usuario)}
              aria-label={`Eliminar a ${usuario.primer_nombre}`}
            />
          </HStack>
        </Flex>
      ))}
      {usuariosVisibles < usuarios.length && (
        <Flex justify="center">
          <Text>Cargando más usuarios...</Text>
        </Flex>
      )}
    </Box>
  );
};

export default UsuarioCard;
