import React, { useEffect, useState } from 'react';
import { FaSkullCrossbones } from 'react-icons/fa';
import { Spinner } from '@chakra-ui/react';

const CalaveraNoChilla = ({ id }) => {
  const [calavera, setCalavera] = useState(0);
  const [loading, setLoading] = useState(true);
  async function getUsuario() {
    const url = `https://putipatrulla-dev-dcrj.2.us-1.fl0.io/usuario/${id}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      // Suponiendo que la respuesta es un objeto de usuario y no un array
      setCalavera(data[0].eliminado === 1 ? 1 : 0);
      console.log(data);
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
    }
  }

  useEffect(() => {
    getUsuario().then(() => setLoading(false));
    return () => setLoading(true);
  }, [id]);

  return (
    <>
      {!loading ? (
        <FaSkullCrossbones color={calavera === 1 ? 'red' : 'gray'} />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default CalaveraNoChilla;
