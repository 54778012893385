import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UsuarioCard from './UsuarioCard';
import { Box, Text } from '@chakra-ui/react';

// El componente Usuarios ahora renderiza una lista de UsuarioCard
const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    const idUsuarioAdmin = localStorage.getItem('id');
    axios
      .get(
        `https://putipatrulla-dev-dcrj.2.us-1.fl0.io/usuarios-grupos-canales/${idUsuarioAdmin}`
      )
      .then(response => {
        setUsuarios(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const [recargar, setRecargar] = useState(false);
  const deleteUser = id => {
    setUsuarios(usuarios.filter(usuario => usuario.id !== id));
    setRecargar(true);
    setTimeout(() => {
      setRecargar(false);
    }, 1000);
  };

  return (
    <Box bg="blue.800" color="white" p={4} width={'100wh'}>
      <Text fontSize="2xl" mb={4}>
        Tus Usuarios
      </Text>
      {usuarios.length > 0 && !recargar ? (
        <UsuarioCard ids={usuarios} onDelete={deleteUser} />
      ) : (
        <p>Cargando usuarios...</p>
      )}
    </Box>
  );
};

export default Usuarios;
