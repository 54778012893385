import React, { useState, useEffect } from 'react';
import { Avatar } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
function checkImageUrl(url, callback) {
  const img = new Image();
  img.src = url;

  img.onload = () => {
    callback(true);
  };

  img.onerror = () => {
    callback(false);
  };
}
function getInitials(usuario) {
  let initials = '';

  if (usuario.primer_nombre) {
    initials += usuario.primer_nombre.charAt(0).toUpperCase();
  }

  if (usuario.apellido) {
    initials += usuario.apellido.charAt(0).toUpperCase();
  }

  if (!initials && usuario.nombre_usuario) {
    initials = usuario.nombre_usuario.charAt(0).toUpperCase();
  }

  return initials;
}

function UsuarioAvatar({ usuario }) {
  const [isValidUrl, setIsValidUrl] = useState(true);
  const initials = getInitials(usuario);

  useEffect(() => {
    checkImageUrl(usuario.urlImagen, isValid => {
      setIsValidUrl(isValid);
    });
  }, [usuario.urlImagen]);

  return (
    <>
      {isValidUrl ? (
        <Avatar src={usuario.urlImagen} size="md" />
      ) : (
        <Avatar
          bg="teal.500"
          size="md"
          name={
            usuario.primer_nombre + ' ' + usuario.apellido !== null
              ? usuario.primer_nombre
              : ''
          }
        ></Avatar>
      )}
    </>
  );
}

export default UsuarioAvatar;
