import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import './estilos.css';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home'; // Importa tu componente de inicio
import Usuarios from './Usuarios'; // Importa tu componente de usuarios
//import Usuarios from './Usuarios'; // Importa tu componente de usuarios

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: '#fad4eb',
      },
    },
  },
});
const handleTelegramResponse = response => {
  console.log(response);

  const userData = {
    id_telegram: response.id,
    nombre_usuario: response.username,
    fecha_registro: new Date().toISOString(),
  };
  console.log(userData);
  axios
    .post('https://putipatrulla-dev-dcrj.2.us-1.fl0.io/auth/telegram', userData)
    .then(response => {
      console.log('Datos guardados en la base de datos:', response.data);
    })
    .catch(error => {
      console.error('Error al guardar los datos en la base de datos:', error);
    });
};
const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/usuarios" element={<Usuarios />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
