import React, { useEffect } from 'react';
import { Box, VStack, Grid } from '@chakra-ui/react';
import { Logo } from './Logo';
import './estilos.css';
import { Text } from '@chakra-ui/react';
import TelegramLoginButton from 'react-telegram-login';
import axios from 'axios';

const handleTelegramResponse = response => {
  console.log(response);
  localStorage.setItem('id_telegram', response.id);

  const userData = {
    id_telegram: response.id,
    nombre_usuario: response.username,
    fecha_registro: new Date().toISOString(),
  };
  console.log(userData);
  axios
    .post('https://putipatrulla-dev-dcrj.2.us-1.fl0.io/auth/telegram', userData)
    .then(response => {
      console.log(
        'Datos guardados en la base de datos:',
        response.data,
        response.data.user !== null
      );
      if (response.data.user !== null) {
        localStorage.setItem('id', response.data.user);
        window.location.href = '/usuarios';
      } else {
        alert('error, logueate de nuevo');
        window.location.reload(true);
      }
    })
    .catch(error => {
      console.error('Error al guardar los datos en la base de datos:', error);
    });
};
const App = () => {
  return (
    <VStack spacing={8}>
      <Logo h="40vmin" pointerEvents="none" />
      <TelegramLoginButton
        botName="putipatrulla_bot"
        buttonSize="large"
        dataOnauth={handleTelegramResponse}
        usePic={false}
      />
    </VStack>
  );
};

export default App;
